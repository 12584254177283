<template>
  <div class="weui-msg">
    <div class="weui-msg__icon-area" id="dv_icon"><i class="weui-icon-success weui-icon_msg"></i></div>
    <div class="weui-msg__text-area">
      <h2 class="weui-msg__title" id="h_title" runat="server">操作成功</h2>
    </div>
    <div class="weui-msg__opr-area">
      <p class="weui-btn-area">
        <a class="weui-btn weui-btn_primary" @click="GoHome">返回报名页</a>
      </p>
    </div>

  </div>
</template>

<script>
export default {
name: "index",
  data(){
  return{

  };
  },
  methods:{
    GoHome(){
      this.$router.push({
        path: '/'
      })
    }
  }
}
</script>

<style scoped>

</style>